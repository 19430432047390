import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IStudentQuestionAnswerMetrics } from "@/interfaces/IStudentQuestionAnswerMetrics";
import { IStudentEducation } from "@/interfaces/IStudentEducation";

const URL_STUDENT_QUESTION_ANSWERS = "student-question-answers";

@Component
export default class LearnStatusRequestMixin extends Vue {
  public studentQuestionAnswerMetrics: Array<{ metrics: IStudentQuestionAnswerMetrics; studentEducationId: number; licenseClass: string }> = [];
  public studentQuestionAnswerMetricsLoading = false;
  public studentQuestionAnswerMetricsSuccess = false;
  public studentQuestionAnswerMetricsError: Record<any, any> | null = null;

  public fetchStudentQuestionAnswerMetricsByStudentEducations(studentEducations: Array<IStudentEducation>) {
    this.studentQuestionAnswerMetricsLoading = true;
    this.studentQuestionAnswerMetricsSuccess = false;
    this.studentQuestionAnswerMetricsError = null;
    Promise.all(
      studentEducations.map((edu: IStudentEducation) => {
        if (edu.id && edu.mainLicenseClass) {
          return this.queryBuilder(edu.id, edu.mainLicenseClass);
        }
      })
    )
      .then((response: any) => {
        this.studentQuestionAnswerMetrics = response;
        this.studentQuestionAnswerMetricsSuccess = true;
      })
      .catch((error: any) => {
        this.studentQuestionAnswerMetricsError = error;
      })
      .finally(() => {
        this.studentQuestionAnswerMetricsLoading = false;
      });
  }

  private queryBuilder(studentEducationId: number, licenseClass: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${URL_STUDENT_QUESTION_ANSWERS}/metrics-for-fsc?studentEducationId=${studentEducationId}`)
        .then((response: AxiosResponse) => {
          resolve({
            metrics: response.data,
            studentEducationId,
            licenseClass,
          });
        })
        .catch((error: AxiosError) => {
          reject(error.response?.data);
        });
    });
  }

  public async updateMetricsByStudentEducations(studentEducations: Array<IStudentEducation>) {
    this.studentQuestionAnswerMetricsLoading = true;
    this.studentQuestionAnswerMetricsSuccess = false;
    this.studentQuestionAnswerMetricsError = null;
    await Promise.all(
      studentEducations.map(async (edu: IStudentEducation) => {
        if (edu.id && edu.mainLicenseClass) {
          return await this.updateMetricsQueryBuilder(edu.id);
        }
      })
    )
      .catch((error: any) => {
        this.studentQuestionAnswerMetricsError = error;
      })
      .finally(() => {
        this.studentQuestionAnswerMetricsLoading = false;
      });
  }

  private async updateMetricsQueryBuilder(studentEducationId: number): Promise<any> {
    return await axios.post(`${URL_STUDENT_QUESTION_ANSWERS}/metrics-for-fsc/update?studentEducationId=${studentEducationId}`);
  }
}
