













































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StudentQuestionAnswersModule = namespace("student-question-answers");

@Component({
  components: {},
})
export default class StudentQuestionsImgNegative extends Vue {
  @Prop()
  public studentEducation!: any;
  @Prop()
  public learningMode!: any;
  @Prop()
  public stats: any;
  @Prop()
  public loading: any;

  get incorrectlyAnsweredX(): number {
    if (!this.stats || !this.stats.incorrectlyAnswered) {
      return -20;
    }
    if (this.stats.incorrectlyAnswered >= 100) {
      return -40;
    }
    if (this.stats.incorrectlyAnswered >= 10) {
      return -31;
    }
    return -20;
  }

  get readyForExamX(): number {
    if (!this.stats || !this.stats.readyForExam) {
      return -10;
    }
    if (this.stats.readyForExam >= 100) {
      return -28;
    }
    if (this.stats.readyForExam >= 10) {
      return -20;
    }
    return -10;
  }

  get oneTimeAnsweredX(): number {
    if (!this.stats || !this.stats.correctlyAnswered) {
      return 18;
    }
    if (this.stats.correctlyAnswered >= 100) {
      return -2;
    }
    if (this.stats.correctlyAnswered >= 10) {
      return 6;
    }
    return 18;
  }

  get remainingMidX(): number {
    if (!this.stats || !this.stats.remaining) {
      return 18;
    }

    if (this.stats.remaining >= 1000) {
      return -11;
    }
    if (this.stats.remaining >= 100) {
      return -2;
    }
    if (this.stats.remaining >= 10) {
      return 8;
    }
    return 18;
  }

  get remainingX(): number {
    if (!this.stats || !this.stats.remaining) {
      return 18;
    }
    if (this.stats.remaining >= 1000) {
      return -11;
    }
    if (this.stats.remaining >= 100) {
      return -2;
    }
    if (this.stats.remaining >= 10) {
      return 8;
    }
    return 18;
  }
}
