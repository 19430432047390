import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ITheoryQuestionWrongAnswer } from "@/interfaces/ITheoryQuestionWrongAnswer";

const URL = "theory-questions";

@Component
export default class TheoryQuestionRequestMixin extends Vue {
  public theoryQuestionMoreThanTwoWrongAnswers: Array<ITheoryQuestionWrongAnswer> = [];
  public theoryQuestionMoreThanTwoWrongAnswersLoading = false;
  public theoryQuestionMoreThanTwoWrongAnswersSuccess = false;
  public theoryQuestionMoreThanTwoWrongAnswersError: Record<any, any> | null = null;

  public async fetchTheoryQuestionMoreThanTwoWrongAnswers(studentId: number): Promise<void> {
    this.theoryQuestionMoreThanTwoWrongAnswersLoading = true;
    this.theoryQuestionMoreThanTwoWrongAnswersSuccess = false;
    this.theoryQuestionMoreThanTwoWrongAnswersError = null;
    return await axios
      .get(`${URL}/student/${studentId}/more-than-two-wrong-answers`)
      .then((response: AxiosResponse) => {
        this.theoryQuestionMoreThanTwoWrongAnswers = response.data;
        this.theoryQuestionMoreThanTwoWrongAnswersSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.theoryQuestionMoreThanTwoWrongAnswersError = error.response?.data;
      })
      .finally(() => {
        this.theoryQuestionMoreThanTwoWrongAnswersLoading = false;
      });
  }
}
